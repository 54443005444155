import React from 'react';
import './index.css';
import { Button } from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import generate_images_bg_1 from '../../../images/generate_images_bg_1.png';
import generate_images_bg_2 from '../../../images/generate_images_bg_2.png';
import generate_images_bg_3 from '../../../images/generate_images_bg_3.png';
import home_styleid_bg from '../../../images/home_styleid_bg.png';
import home_faceid_bg from '../../../images/home_faceid_bg.png';
import home_duo_bg from '../../../images/home_duo_bg.png';
import home_headshot_bg from '../../../images/home_headshot_bg.png';

const ContentFour = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onGenerateClick = (key) => {
        if (key === 'Headshot') {
            navigate(`/headshot`);
            return;
        }
        navigate(`/app/${key}`);
    };

    return (
        <div className='content-four'>
            <div className='content-four-one'>
                <div className='content-row'>
                    <div className="content-row-img">
                        <img src={home_styleid_bg} alt="" />
                    </div>
                    <div className="content-row-text">
                        <div className='text'>
                            <h2>{t('home.bodyone.title')}</h2>
                            <div>
                                <p>{t('home.bodyone.introduction')}</p>
                                <p>{t('home.bodyone.body')}</p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('StyleID')}
                        >{t('home.bodyone.but')}</Button>
                    </div>
                </div>
            </div>
            <div className='content-four-two'>
                <div className='content-row'>
                    <div className="content-row-text">
                        <div className='text'>
                            <h2>{t('home.bodytwo.title')}</h2>
                            <div>
                                <p>{t('home.bodytwo.introduction')}</p>
                                <p>{t('home.bodytwo.body')}</p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('FaceID')}
                        >{t('home.bodytwo.but')}</Button>
                    </div>
                    <div className="content-row-img">
                        <img src={home_faceid_bg} alt=""/>
                    </div>
                </div>
            </div>
            <div className='content-four-three'>
                <div className='content-row'>
                    <div className="content-row-img">
                        <img src={home_duo_bg} alt=""/>
                    </div>
                    <div className="content-row-text">
                        <div className='text'>
                            <h2>{t('home.bodythree.title')}</h2>
                            <div>
                                <p>{t('home.bodythree.introduction')}</p>
                                <p>{t('home.bodythree.body')}</p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('Duo')}
                        >{t('home.bodythree.but')}</Button>
                    </div>
                </div>
            </div>
            <div className='content-four-two'>
                <div className='content-row'>
                    <div className="content-row-text">
                        <div className='text'>
                            <h2>{t('home.bodyfive.title')}</h2>
                            <div>
                                <p>{t('home.bodyfive.introduction')}</p>
                                <p>{t('home.bodyfive.body')}</p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('Headshot')}
                        >{t('home.bodyfive.but')}</Button>
                    </div>
                    <div className="content-row-img">
                        <img src={home_headshot_bg} alt=""/>
                    </div>
                </div>
            </div>
            <div className='content-four-four'>
                <div className='content-row'>
                    <div className='title'>{t('home.bodyfour.title')}</div>
                    <div className='alter'>{t('home.bodyfour.alter')}</div>
                    <div className='content'>
                        <div className='image-box'>
                            <div className='box'>
                                <img src={generate_images_bg_1} alt="" />
                                <div>{t('home.bodyfour.text1')}</div>
                            </div>
                            <div className='icon'>
                                <RightOutlined />
                            </div>
                            <div className='box'>
                                <img src={generate_images_bg_2} alt="" />
                                <div>{t('home.bodyfour.text2')}</div>
                            </div>
                            <div className='icon'>
                                <RightOutlined />
                            </div>
                            <div className='box'>
                                <img src={generate_images_bg_3} alt="" />
                                <div>{t('home.bodyfour.text3')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentFour;