import React, { useState, useEffect, useRef } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Image,
    Skeleton,
    Input,
    Typography
} from 'antd';
import {
    RightOutlined,
    PlusOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {
    HeadShotAPI,
    GetScenesInfo,
    InfoByTaskID
} from '../../api/request.js';
import 'react-resizable/css/styles.css';
import homeicon from '../../images/homeicon.png';
import noimage from '../../images/noimage.png';
import integral_icon from '../../images/integral_icon.png';
import action_loading_error from '../../images/action_loading_error.png';
import { useTasks, useTasksDispatch } from '../../TasksContext';
import { useNavigate } from "react-router-dom";
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import useCatchAsyncError from '../../hooks/useCatchAsyncError.jsx';
import { getdataIDB, deletedataIDB, updataIDB } from '../../api/idb.js';
import NoticeModal from '../../components/noticeModal';
import PubInput from '../../components/pubInput';

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new Blob([u8arr], {type: mime});
}

function getRandom32Bit() {
    return Math.floor(Math.random() * 0xFFFFFFFF);
}


const { TextArea } = Input;
const { Paragraph } = Typography;

const HeadShot = () => {
    const auth = useAuth();
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const myRef = useRef(null);
    const headshotInterval = useRef(null);
    const navigate = useNavigate();
    const setError = useCatchAsyncError();
    const { t } = useTranslation();

    const [isInside, setIsInside] = useState(false);
    const [isMoving, setIsMoving] = useState(false);

    const [renderData, setRenderData] = useState({});
    const [templateImage, setTemplateImage] = useState({});
    const [seed, setSeed] = useState(t('actionpage.headshot.seedplaceholder'));
    const [form] = Form.useForm();
    const [getImageUrl, setGetImageUrl] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [generateImagePreview, setGenerateImagePreview] = useState(0);
    const [submitError, setSubmitError] = useState(false);
    const [initialization, setInitialization] = useState(true);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [allowUpload, setAllowUpload] = useState(false);

    const isHeadShotMountedRef = useRef(true);

    const smoothScrollTo = (element) => {
        const top = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo(0, top);
    };
    const handleSubmit = () => {
        if (auth?.isAuthenticated) {
            if (window.innerWidth < 768) {
                if (myRef.current) {
                    smoothScrollTo(myRef.current)
                }
            }
            form.submit();
        } else {
            const currentUrl = `/headshot`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
        }
    };

    const infoByTaskID = async ({taskid, uid, token}) => {
        const res = await InfoByTaskID({ taskid, uid, token });
        if (res.data?.data?.image_status === 4 || (res?.error !== null && res?.error?.code !== 'ECONNABORTED')) {
            clearInterval(headshotInterval.current);
            message.error(t('actionpage.tomyspacemessage'));
            setSubmitError(true);
            setDisabledSubmit(false);
        } else {
            if (res.data?.data?.url) {
                setGetImageUrl(res.data.data.url);
                dispatch({
                    type: 'set_share_modal_data',
                    data: {
                        url: res.data.data.url,
                        category: res.data.data.category,
                        taskid: res.data.data.taskid
                    }
                });
                dispatch({
                    type: 'set_scene_gen_status',
                    value: 3,
                });
                clearInterval(headshotInterval.current);
                setDisabledSubmit(false);
            }
        }
    }
    const handleFormSubmit = async (values) => {
        dispatch({
            type: 'set_share_modal_data',
            data: {}
        });
        setSubmitError(false);
        if (initialization) {
            setInitialization(false);
        }
        if (auth.isAuthenticated) {
            setDisabledSubmit(true);
            try {
                setGetImageUrl('');
                dispatch({
                    type: 'set_scene_gen_status',
                    value: 1,
                });
                const stochasticSeed = getRandom32Bit();
                const formData = new FormData();
                for (let i = 0; i < values.source.length; i++) {
                    formData.append('sourceImage', values.source[i].originFileObj);
                }
                formData.append('category', templateImage.category);
                formData.append('positivePrompt', values.text);
                if (values.seed) {
                    formData.append('seed', Number(values.seed));
                } else {
                    setSeed(stochasticSeed);
                    formData.append('seed', stochasticSeed);
                }
                formData.append('uid', auth.user.profile.sub);
                const res = await HeadShotAPI({
                    formData: formData,
                    token: auth.user.access_token,
                });
                if (res?.data?.code === 1000) {
                    if (isHeadShotMountedRef.current) {
                        headshotInterval.current = setInterval(() => {
                            infoByTaskID({ taskid: res.data.data.taskid, uid: auth.user.profile.sub, token: auth.user.access_token });
                        }, 5000);
                    }
                    dispatch({
                        type: 'set_is_user_loading_image',
                        data: true,
                    });
                } else if (res?.data?.code === 9000) {
                    message.error(t('actionpage.nopointserrormessage'));
                    setDisabledSubmit(false);
                    setSubmitError(true);
                } else if (res?.error?.code === 'ERR_NETWORK') {
                    message.error(t('actionpage.networkerrormessage'));
                    setSubmitError(true);
                    setError(res?.error)
                } else {
                    message.error(t('actionpage.serviceerrormessage'));
                    setSubmitError(true);
                    setDisabledSubmit(false);
                }
            } catch (error) {
                message.error(t('actionpage.serviceerrormessage'));
                setSubmitError(true);
                setDisabledSubmit(false);
            }
        } else {
            const currentUrl = `/headshot`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload = async (file) => {
        if (auth?.isAuthenticated) {
            try {
                const isJpgOrPng = file.type.startsWith('image');
                if (!isJpgOrPng) {
                    message.error(t('actionpage.uploadifimagemessage'));
                    return Upload.LIST_IGNORE || false;
                }
                const isLt2M = file.size / 1024 / 1024 < 6;
                if (!isLt2M) {
                    message.error(t('actionpage.uploadifimagesizemessage'));
                    return Upload.LIST_IGNORE || false;
                }
    
                // return isLt2M && isJpgOrPng;
                return false;
            } catch (error) {
                message.error(t('actionpage.serviceerrormessage'));
                // return Upload.LIST_IGNORE;
                return false;
            }
        } else {
            const currentUrl = `/headshot`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
            return false;
        }
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const openFileDialogOnClick = () => {
        if (auth?.isAuthenticated) {
            setAllowUpload(true);
        } else {
            const currentUrl = `/headshot`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
            setAllowUpload(false);
        }
    };
    const handleChange = ({ fileList: newFileList }) => {
        if (auth?.isAuthenticated) {
            setFileList(newFileList);
            try {
                const reader = new FileReader();
                reader.onload = function(event) {
                    const base64String = event.target.result;
                    updataIDB({ id: 'useHeadShotImage', image: base64String })
                };
                reader.readAsDataURL(newFileList[0].originFileObj);
            } catch (error) { /* empty */ }
        } else {
            const currentUrl = `/headshot`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
        }
    };

    const uploadButton = (
        <button type="button" onClick={openFileDialogOnClick}>
            <PlusOutlined />
            <div>{t('actionpage.uploadbut')}</div>
        </button>
    );

    const handleMouseEnter = () => {
        setIsInside(true);
    };
    const handleMouseLeave = () => {
        setIsInside(false);
    };
    const handleMouseMove = () => {
        if (isInside) {
            setIsMoving(true);
        }
    };

    const onVisibleChange = (visible) => {
        setPreviewOpen(visible);
    };

    const afterOpenChange = (visible) => {
        !visible && setPreviewImage('');
    };

    const onRemoveImage = () => {
        try {
            deletedataIDB('useHeadShotImage');
            setFileList([]);
            setPreviewImage('');
            setPreviewOpen(false);
        } catch (error) { /* empty */ }
    };

    const noticeModalShow = () => {
        dispatch({
            type: 'set_notice_modal_show',
            data: true
        });
    };

    const tagClick = (item) => {
        setGetImageUrl([item.scenes[0].imageurl]);
        if (form) {
            form.setFieldsValue({
                text: item.scenes[0].positive
            })
        }
        setTemplateImage(item);
    }

    useEffect(() => {
        setAllowUpload(auth?.isAuthenticated);
    }, [auth?.isAuthenticated]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async() => {
            const playgroundData = await GetScenesInfo({ type: 4 });
            if (playgroundData?.data?.code === 1000) {
                const getScenesList = JSON.parse(JSON.stringify(playgroundData.data.data || []));
                if (getScenesList.length > 0) {
                    
                    for (let i = 0; i < getScenesList.length; i++) {
                        if (getScenesList[i].scenes) {
                            getScenesList[i].scenes = getScenesList[i].scenes.sort((a, b) => a.type - b.type)
                        }
                    }
                    getScenesList.sort((a, b) => a.order - b.order)
                    
                }
                setGetImageUrl([getScenesList[0].scenes[0].imageurl]);
                if (form) {
                    form.setFieldsValue({
                        text: getScenesList[0].scenes[0].positive
                    })
                }
                setRenderData(getScenesList);
                setTemplateImage(getScenesList[0])
            } else {
                setError(playgroundData.error)
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            const getUseFaceIDImage = await getdataIDB('useHeadShotImage');
            if (getUseFaceIDImage && auth?.isAuthenticated) {
                const base64String = getUseFaceIDImage.image;
                if (base64String) {
                    const blob = dataURLtoBlob(base64String);
                    const file = new File([blob], "useHeadShotImage.png", { type: 'image/png' });
                    let thumbUrl = await getBase64(file);
                    setFileList([{
                        name: file.name,
                        status: 'done',
                        url: URL.createObjectURL(file),
                        originFileObj: file,
                    }]);
                    if (form) {
                        form.setFieldsValue({
                            source: [{
                                name: file.name,
                                status: 'done',
                                url: URL.createObjectURL(file),
                                originFileObj: file,
                            }]
                        })
                    }
                    setPreviewImage(thumbUrl);
                }
            }
        })()
    }, [auth?.isAuthenticated, form]);
    useEffect(() => {
        isHeadShotMountedRef.current = true;
        return () => {
            isHeadShotMountedRef.current = false;
            if (headshotInterval) {
                clearInterval(headshotInterval.current);
            }
        }
    }, []);

    return (<div className="headshot-bg">
        <div className="headshot">
            <div className="headshot-header">
                <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
                <div><RightOutlined/></div>
                <div>{t('actionpage.headshot.title')}</div>
            </div>
            <div className="headshot-box">
                <div className="prompt">
                    <Form
                        form={form}
                        name="validate_other"
                        onFinish={handleFormSubmit}
                        className='form'
                    >
                        <div className="upload">
                            <div className="template">
                                <div className="title">{t('actionpage.headshot.themestitle')}</div>
                                <div className="input">
                                    <Form.Item
                                        name='text'
                                        noStyle
                                        rules={[{ required: true, message: '' }]}
                                    >
                                        <TextArea
                                            autoSize={true}
                                            className="text-area-item"
                                            disabled={disabledSubmit}
                                        />
                                    </Form.Item>
                                    <PubInput
                                        title={t('actionpage.headshot.seedlabel')}
                                        name='seed'
                                        typr='number'
                                        placeholder={seed}
                                        disabled={disabledSubmit}
                                        iconTitle={t('actionpage.headshot.seedicontitle')}
                                    />
                                </div>
                                <div className="footer">
                                    <div>
                                        <img src={integral_icon} alt=""/>
                                        <div>{templateImage.point || ""}</div>
                                    </div>
                                    <div>{ templateImage.batchsize ? t('actionpage.generateimagenum', { num: templateImage.batchsize }) : ""}</div>
                                </div>
                            </div>
                            <div className="action-box">
                                <div className="title">{t('actionpage.uploadtitle')}</div>
                                <div className="action">
                                    <Form.Item
                                        name="source"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        rules={[{ required: true, message: '' }]}
                                        className="upload-item"
                                    >
                                        <Upload
                                            action={null}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            name="source"
                                            maxCount={1}
                                            beforeUpload={beforeUpload}
                                            className='upload-source'
                                            disabled={disabledSubmit}
                                            openFileDialogOnClick={allowUpload}
                                            onRemove={onRemoveImage}
                                        >
                                            {fileList.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Form.Item>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{
                                                display: 'none',
                                            }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: onVisibleChange,
                                                afterOpenChange: afterOpenChange,
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                    {
                                        fileList.length < 1 ?
                                        <ExclamationCircleOutlined
                                            className="notice"
                                            onClick={noticeModalShow}
                                        /> : <></>
                                    }
                                    <NoticeModal/>
                                </div>
                                <div className="footer">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        size="large"
                                        className="but"
                                        onClick={handleSubmit}
                                        disabled={disabledSubmit}
                                    >
                                        {t('actionpage.generate')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="output-image" style={{ backgroundColor: getImageUrl.length < 1 ? 'rgba(222, 222, 222, 1)' : 'rgba(255, 255, 255, 1)' }}>
                    <div className="output-image-bfc" ref={myRef}>
                        {
                            getImageUrl.length > 0 ? <>
                                <div className="container">
                                    <div
                                        id="hover-area"
                                        className="main-image"
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        onMouseMove={handleMouseMove}
                                    >
                                        <Image
                                            alt={getImageUrl[generateImagePreview]}
                                            src={getImageUrl[generateImagePreview]}
                                            preview={tasks[2].windowWidth < 768 ? true : isMoving}
                                            placeholder={<div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}><Skeleton.Image active={true} className="main-image"/></div>}
                                        />
                                    </div>
                                    {
                                        getImageUrl.length > 0 ?
                                        <div className="thumbnail-container">
                                            {
                                                getImageUrl.length > 0 ?
                                                getImageUrl.map((item, index) => <div className="thumbnail" key={item}>
                                                    <div
                                                        onClick={() => setGenerateImagePreview(index)}
                                                        style={{
                                                            border: `2px solid ${generateImagePreview === index ? "rgba(25, 73, 255, 1)" : "rgba(25, 73, 255, 0)"}`
                                                        }}
                                                    >
                                                        <Image
                                                            alt={item}
                                                            src={item}
                                                            preview={false}
                                                            placeholder={<div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                                        />
                                                    </div>
                                                </div> ) : <></>
                                            }
                                        </div> : <></>
                                    }
                                </div>
                            </> : initialization ?
                            <div className="noimage-box">
                                <img className="noimage" src={noimage} alt="" />
                            </div> : <></>
                        }
                        {
                            disabledSubmit && !submitError && getImageUrl.length <=0 && !initialization ?
                            <div className="loading-box">
                                <img className="noimage" src={noimage} alt="" />
                                <div>{t('actionpage.loadingimage')}</div>
                            </div> : <></>
                        }
                        {
                            submitError && !disabledSubmit && getImageUrl.length <=0 && !initialization ?
                                <div className="error-box">
                                    <img src={action_loading_error} alt="" />
                                    <div>{t('actionpage.loadingimageerror')}</div>
                                </div> : <></>
                        }
                    </div>
                </div>
            </div>
            {
                renderData.length > 1 ?
                <div className="headshot-template-label">
                    {t('actionpage.headshot.label')}
                </div> : <></>
            }
            <div className="headshot-footer">
                {
                    renderData.length > 1 ? renderData.map((item) => {
                        if (item.category !== 'DEFAULT') {
                            return <div
                                key={item.category}
                                className="footer-tag"
                                onClick={disabledSubmit ? ()=>{} : () => tagClick(item)}
                                style={{
                                    backgroundColor: item.category === templateImage.category ? 'rgba(25, 73, 255, 0.50)' : '',
                                    color: item.category === templateImage.category ? 'rgba(255, 255, 255, 1)' : ''
                                }}
                                disabled={disabledSubmit}
                            >
                                <div>
                                    <img src={item.scenes[0].imageurl} alt={item.scenes[0].scenename} />
                                    <div>{item.category}</div>
                                </div>
                                <Paragraph
                                    ellipsis={{
                                        rows: 4,
                                        expandable: false,
                                    }}
                                    style={{
                                        color: item.category === templateImage.category ? 'rgba(255, 255, 255, 1)' : ''
                                    }}
                                >
                                    {item.scenes[0].positive}
                                </Paragraph>
                            </div>
                        } else {
                            return null;
                        }
                    }) : <></>
                }
            </div>
        </div>
    </div>)
};
export default HeadShot;