const en = {
    header: {
        home: 'Home',
        app: 'App',
        pricing: 'Pricing',
        contactus: 'Contact Us',
        myspace: 'My Space',
        login: 'Log In',
        signup: 'Signup',
        share: 'Invite Friends',
        submenu: {
            styleid: {
                title: 'StyleID',
                appendices: 'Artistic Avatar'
            },
            faceid: {
                title: 'FaceID',
                appendices: 'Realistic Avatar'
            },
            duo: {
                title: 'Duo',
                appendices: 'Duo Portrait'
            },
            headshot: {
                title: 'Headshot',
                appendices: 'Open Prompt Portrait'
            }
        },
        usersubmenu: {
            transactions: 'Transactions',
            logout: 'Log Out'
        },
        logininterceptmodal: {
            title: 'Register Award',
            footerbut: 'Log In Now',
            checktext: 'Do not show this again',
            body: 'Get 5,000 Free Points and 300 daily bonus for Registration!',
            appendices: 'Join us and take advantage of our exclusive offer for new users. Start your experience right now!'
        }
    },
    footer: {
        contactus: 'Contact Us',
        copyrightnotice: 'GoodApp AI © 2024  All rights reserved.'
    },
    page404: {
        content: 'No Internet connection',
        refreshbut: 'Refresh'
    },
    home: {
        title: "Enhance Your Web Experience with AI",
        Introduction: 'GoodApp AI Presents Best AI Tools for Your Daily Essentials!',
        activitycontent: 'Register now for 5,000 free points and 300 daily bonus!',
        gobut: 'Go',
        selectoptions: {
            styleid: 'StyleID',
            faceid: 'FaceID',
            duo: 'Duo',
            headshot: 'Headshot'
        },
        bodyone: {
            title: 'StyleID',
            introduction: 'Transform Your Look with Artistic Flair.',
            body: "Unleash your creativity with our unique avatar generator! \
                Choose from a variety of vibrant artistic styles to create a stunning, \
                personalized avatar that truly represents you. Whether you prefer bold \
                brush strokes or delicate illustrations, our tool allows you to express \
                your individuality like never before. Stand out on social media and make \
                a statement with a custom avatar that's as unique as you are!",
            but: 'Generate'
        },
        bodytwo: {
            title: 'FaceID',
            introduction: 'Create Realistic Avatar with a Personal Touch.',
            body: "Capture your essence with our realistic portrait generator. \
                This tool delivers lifelike avatars that maintain a subtle artistic \
                flair while emphasizing authenticity. Perfect for those who appreciate \
                detail and realism, you can customize features to reflect your unique \
                personality. Ideal for professional profiles or personal branding, \
                help you leave a lasting impression.",
            but: 'Generate'
        },
        bodythree: {
            title: 'Duo',
            introduction: 'Capture Special Moments with Duo Portraits.',
            body: "Celebrate life's milestones with our duo portrait generator! \
                Effortlessly create stunning photos featuring you and a partner \
                in any chosen setting—be it a joyful celebration, a cherished memory, \
                or a casual moment. Our tool allows you to personalize your scene, \
                ensuring each snapshot uniquely reflects your bond.",
            but: 'Generate'
        },
        bodyfour: {
            title: 'How it works?',
            alter: 'Choose a template and upload your photo to generate exciting images',
            text1: 'Select App',
            text2: 'Select template',
            text3: 'Upload photo to generate'
        },
        bodyfive: {
            title: 'Headshot',
            introduction: 'Generate any scene of your imagination.',
            body: "Upload a portrait, describe the scene you envision, and watch as\
             our AI brings your vision to life, placing your subject right into the setting\
              you've dreamed of.Whether it's a vacation spot, a cozy home, or a grand\
               adventure, we can seamlessly integrates your face into the described scene,\
                creating a personalized, lifelike image.",
            but: 'Generate'
        },
    },
    myspace: {
        myspace: 'My Space',
        transaction: 'Transaction',
        allbut: 'All',
        deletebut: 'Delete',
        cancelbut: 'Cancel',
        editbut: 'Edit',
        deletemodal: {
            cancelbut: 'Cancel',
            confirmbut: 'Confirm',
            bodytext1: 'Are you sure to delete this work?',
            bodytext2: 'Once deleted, it cannot be restored.',
        },
        errormessage: 'Image generation failure',
        generatebut: 'Generate',
        loadingmessage: 'Image generating',
        nodatatext: 'No picture available at the moment',
        nodatabut: 'Back',
        table: {
            nodatatext: 'No recharge records available at the moment',
            data: 'Date',
            operation: 'Operation',
            points: 'Points'
        },
        sharemodal: {
            shareerrormessage: 'Share Failed',
            copytext: 'Try our fantastic AI generated portrait on GoodApp AI, for free!\n{{url}}/share?sharecode={{sharecode}}',
            copysucceedmessage: 'Copy Successful',
            contentfootertext: 'GoodApp AI - Create stunning AI portraits and more! Sign up for a 5,000-point bonus, and both you and your friend will earn an extra 1,500 points for every successful referral!',
            saveimagebut: 'Save Image',
            sharelinkbut: 'Share Link'
        }
    },
    pageloading: 'Loading...',
    app: {
        infoerrormessage: 'Please refresh and try again later!',
        generatebut: 'Generate',
        styleid: {
            title: 'StyleID',
            introduction: 'Generate Personalized Portraits with One Click, Choose from a Vast Array of Templates',
            body: "Choose your preferred style from our meticulously designed templates — whether it's trendy fashion, romantic vintage, or cutting-edge technology. Then, upload a reference image you love along with a clear personal photo, and our powerful AI tools will instantly generate your unique portrait.",
            conclusion: 'Experience it now and embark on your personalized photography journey!'
        },
        faceid: {
            title: 'FaceID',
            introduction: 'Create Fantasy Portraits with Creative Prompts Using AI',
            body: 'Simply upload a clear portrait and add your creative prompts, and our AI engine will transform your ideas into unique portrait photos. From futuristic sci-fi and fantasy adventures to fairy-tale worlds, any scene you envision can be brought to life in your photos.',
            conclusion: 'Start now and create your own visual legend!'
        },
        duo: {
            title: 'Duo',
            bodytitle: 'Duo Portrait',
            introduction: 'Fun Duo Photos, Capture Beautiful Moments with Your Loved Ones',
            body: "Upload two clear portrait photos, and you can generate a variety of fun duo portraits with your family and friends. Whether it's romantic couple photos, joyful friend snapshots, or warm family portraits, we help you capture beautiful moments.",
            conclusion: 'Experience it now and create memories with your lover!'
        }
    },
    actionpage: {
        noticemodal: {
            title: 'Please upload a well-lit half-body photo with clear facial features',
            alter: 'Using the following image MAY NOT meet your expectations:',
            text1: 'Full-body photos',
            text2: 'Photos containing multiple subjects',
            text3: 'Black and white photos or low-quality images',
            text4: 'Photos where the face is covered by masks sunglasses, hands, shadows, etc.',
            text5: 'Photos with incomplete facial features, open mouth and show teeth',
            text6: 'Overdone the beauty and make-up',
            text7: 'Crooked headshot, incomplete head',
            text8: 'NSFW or illegal photos',
        },
        sharelinkbut: 'Click to Share and Earn Points',
        serviceerrormessage: 'Please refresh and try again later.',
        tomyspacemessage: 'Image generation failed, please go to My Space to regenerate.',
        uploadbut: 'Upload',
        nopointserrormessage: 'Your points are running low, get more from our sale!',
        networkerrormessage: 'Internet connection error.',
        uploadifimagemessage: 'You can only upload image files.',
        uploadifimagesizemessage: 'The image must be less than 6MB.',
        themestitle: 'Themes',
        generateimagenum: 'Generate {{num}} artworks a time.',
        uploadtitle: 'Upload your image',
        generate: 'Generate',
        loadingimage: 'Generating expected to take several seconds, please wait patiently',
        loadingimageerror: 'Image load failed',
        styleid: {
            title: 'StyleID'
        },
        faceid: {
            title: 'FaceID'
        },
        duo: {
            title: 'Duo'
        },
        headshot: {
            themestitle: 'Prompt',
            title: 'Headshot',
            label: 'Prompt Template',
            seedlabel: 'Seed',
            seedicontitle: 'Fixed seed could generate fixed image, leave blank for random seed',
            seedplaceholder: 'Please input seed'
        }
    },
    share: {
        sharetext: 'GoodApp AI - Create stunning AI portraits and more! Sign up for a 5,000-point bonus, and both you and your friend will earn an extra 1,500 points for every successful referral!',
        generatebut: 'Generate Now'
    }
};
export default en;